import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import ServiceBoxes from "../components/ServiceBoxes";
import ReviewsCarousel from "../components/ReviewsCarousel";

function HomePage() {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
      mirror: false,
    });
  }, []);

  const services = [
    {
      imgSrc: "/images/icons/cloud-icon.png",
      imgAlt: "Cloud",
      title: "Device and Cloud Management",
      description:
        "We offer device and cloud management services to help businesses securely move to the cloud, store their data, and manage their infrastructure.",
    },
    {
      imgSrc: "/images/icons/cybersecurity-icon.png",
      imgAlt: "Cybersecurity",
      title: "Cybersecurity",
      description:
        "Our cybersecurity services help businesses protect their sensitive information and assets from cyber threats using technical and procedural security measures.",
    },
    {
      imgSrc: "/images/icons/network-monitoring-icon.png",
      imgAlt: "Network Monitoring",
      title: "Network Management and Monitoring",
      description:
        "We ensure that businesses' network infrastructure is secure and performs optimally to prevent downtime and improve productivity.",
    },
    {
      imgSrc: "/images/icons/helpdesk-icon.png",
      imgAlt: "Helpdesk",
      title: "Helpdesk Support",
      description:
        "Our help desk and technical support services assist businesses in resolving technical issues quickly and efficiently, reducing downtime and improving employee productivity.",
    },
    {
      imgSrc: "/images/icons/fractional-cto-icon.png",
      imgAlt: "Fractional CTO",
      title: "Fractional CTO",
      description:
        "We provide fractional CTO services to help businesses prepare for the worst by creating and implementing backup and disaster recovery plans to ensure rapid recovery and minimal data loss.",
    },
    {
      imgSrc: "/images/icons/software-maintanence-icon.png",
      imgAlt: "Software Maintanence",
      title: "Software and Hardware Maintanence",
      description:
        "We help businesses keep their systems up to date and functioning properly, reducing downtime and improving productivity while preventing major issues from arising in the future.",
    },
  ];
  return (
    <>
      <section className="hero | text-center-sm-only padding-block-900">
        <div className="container">
          <div className="even-columns">
            <div className="flow">
              <h1 className="fs-primary-heading fw-bold" data-aos="zoom-in">
                Empowering Small &amp; Medium Sized Businesses with Managed IT
                Solutions
              </h1>
              <p
                data-aos="zoom-in"
                data-aos-duration="800"
                data-aos-delay="600"
              >
                RightClick Professional Services will help you take control of
                your business by providing expert IT support.
              </p>
              <a
                href="https://calendly.com/dgoldshore-rps/rps-introduction?month=2023-04"
                target="_blank"
                className="button"
                data-aos="zoom-in"
                data-aos-duration="1000"
                data-aos-delay="600"
              >
                Book a Consultation
              </a>
            </div>
            <div className="hero_image" data-aos="zoom-in" data-aos-delay="600">
              <img
                className="mx-auto"
                src="/images/illustrations/landing-page-illustration.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <hr className="divider2"></hr>

      <section>
        <div className="container">
          <div className="even-columns">
            <div
              data-aos="zoom-in"
              data-aos-duration="1000"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src="/images/illustrations/locations-illustration.png"
                alt="Consulting Solutions"
                style={{ width: "75%" }}
              />
            </div>
            <div
              className="wide"
              style={{
                display: "flex",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <h2
                style={{ margin: "0 auto" }}
                className="large-subheader"
                data-aos="zoom-in"
                data-aos-delay="600"
              >
                Headquartered in South Norwalk, Connecticut and Brooklyn, New
                York, with a reach that extends far beyond the tri-state area
              </h2>
            </div>
          </div>
        </div>
      </section>

      <ServiceBoxes services={services} />
      <hr className="divider2"></hr>
      <section className="sales-points padding-block-900">
        <div className="container">
          <div className="even-columns">
            <div
              className="sales-points_blog flow text-center-sm-only margin-bottom-700"
              style={{ "--flow-spacer": "1.5rem" }}
            >
              <h2 className="fs-secondary-heading fw-bold" data-aos="zoom-in">
                What's different about RPS?
              </h2>
              <p data-aos="zoom-in" data-aos-duration="200">
                Unlock your business's full potential with affordable,
                transparent pricing, a fractional CTO, and proactive IT
                management from RightClick Professional Services.
              </p>
            </div>
            <div>
              <ul className="numbered-items flow" role="list">
                <li>
                  <div
                    className="flow"
                    style={{ "--flow-spacer": "1em" }}
                    data-aos="slide-up"
                    data-aos-duration="1000"
                  >
                    <h3 className="numbered-items_title fs-600 fw-bold">
                      Affordable and Transparent Pricing
                    </h3>
                    <p className="numbered-items_body" data-width="wide">
                      We offer a range of pricing plans that are designed to fit
                      businesses of all sizes and budgets. Our pricing is
                      transparent, with no hidden fees or surprises. You'll
                      always know exactly what you're paying for, and our
                      pricing is designed to help you save money on IT
                      management.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    className="flow"
                    style={{ "--flow-spacer": "1em" }}
                    data-aos="slide-up"
                    data-aos-duration="1000"
                  >
                    <h3 className="numbered-items_title fs-600 fw-bold">
                      Fractional CTO
                    </h3>
                    <p className="numbered-items_body" data-width="wide">
                      Our Fractional CTO offering is a unique service that sets
                      us apart from our competitors. With this service, you'll
                      have access to a highly experienced professional who will
                      work with you on a part-time basis to develop and execute
                      an IT strategy that aligns with your business goals. This
                      service is perfect for businesses that don't have the
                      budget or need for a full-time CTO, but still want the
                      expertise and guidance of a seasoned IT leader.
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    className="flow"
                    style={{ "--flow-spacer": "1em" }}
                    data-aos="slide-up"
                    data-aos-duration="1000"
                  >
                    <h3 className="numbered-items_title fs-600 fw-bold">
                      Proactive Approach to IT Management
                    </h3>
                    <p className="numbered-items_body" data-width="wide">
                      Our IT Managed Services are designed to proactively
                      identify and address potential IT issues before they
                      impact business operations. Our team of experts
                      continuously monitors your IT infrastructure, identifies
                      potential threats, and takes proactive measures to prevent
                      them, giving you peace of mind.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <ReviewsCarousel />
    </>
  );
}

export default HomePage;
