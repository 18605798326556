import React from "react";

import "../styles/main.css";

function ServiceBoxes({ services }) {
  return (
    <section
      className="services | text-center padding-block-900"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <h2 className="fs-secondary-heading fw-bold">Services We Offer</h2>
      <ul className="services-boxes container | padding-block-700">
        {services.map((service, index) => (
          <li key={index} data-aos="zoom-in" data-aos-duration="600">
            {service.imgSrc && (
              <img
                className="services-icon"
                src={service.imgSrc}
                alt={service.imgAlt}
              />
            )}
            <div className="services-bottom">
              <h3 className="fw-bold">{service.title}</h3>
              <p data-width="wide">{service.description}</p>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default ServiceBoxes;
