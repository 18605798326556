import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import { sanityConfig, imageBuilder } from "../client";

import Header from "../components/Header";
// import DefaultImage from "../../public/images/illustrations/radiant-gradient.png";
import "../styles/resources.css";

function ResourcesPage() {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const query = `*[_type == "post"] | order(publishedAt desc) {
          ...,  // Include other fields you need
          mainImage->{
            altText,
            "imageUrl": url
          },
          categories[]->{
            title
          }
        }`;

        const result = await sanityConfig.fetch(query);

        setPosts(result);
      } catch (error) {
        console.error("Error fetching Sanity blog posts:", error);
      }
    };

    fetchPosts();

    AOS.init({
      duration: 800,
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <>
      <Header title={"Resources"} />
      <section>
        <div className="container | margin-top-500">
          <div className="resources">
            {posts.map((post, index) => {
              const publishedDate = new Date(post.publishedAt);
              const month = publishedDate.toLocaleString("en-US", {
                month: "long",
              });
              const year = publishedDate.getFullYear();

              const isWebinar = post.categories.some(
                (category) => category.title === "Webinar"
              );

              const imageUrl = post.mainImage
                ? imageBuilder.image(post.mainImage).width(200).url()
                : "/images/illustrations/radiant-gradient.png";

              console.log(imageUrl);

              return (
                <div
                  className="resource"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <Link
                    key={index}
                    to={`/post/${post._id}`}
                    className={`resource-link`}
                    onClick={(event) => {
                      event.preventDefault();
                      navigate(`/post/${post._id}`);
                    }}
                  >
                    <div className="resource-content">
                      <div className="resource-image-wrapper">
                        <img src={imageUrl} alt="article-image" />
                      </div>
                      <div className="resource-title-wrapper">
                        <h2 className="resource-title">{post.title}</h2>
                      </div>
                      <div className="resource-details">
                        <span className="resource-type">
                          {post.categories[0]?.title}
                        </span>
                        <span className="resource-date">{`${month} ${year}`}</span>
                      </div>
                      <div className="go-button-wrapper">
                        <button
                          onClick={() => {
                            if (isWebinar && post.url) {
                              window.open(post.url, "_blank"); // Open in a new tab
                            } else {
                              navigate(`/post/${post._id}`);
                            }
                          }}
                          className="go-button"
                        >
                          {isWebinar ? "Watch" : "Read Article"}
                        </button>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default ResourcesPage;
