import React from "react";

import "../scripts/main";
import "../styles/main.css";

const Header = (props) => {
  const { title } = props;
  return (
    <section className="waves-hero container| margin-top-500">
      <h1>{title}</h1>
    </section>
  );
};

export default Header;
