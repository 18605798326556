import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Components
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

//Pages
import HomePage from "./pages/Home";
import ManagedITSupportPage from "./pages/ManagedITSolutions";
import ConsultingSolutionsPage from "./pages/ConsultingSolutions";
import AboutUsPage from "./pages/AboutUs";
import ResourcesPage from "./pages/Resources";
import BlogPostTemplate from "./pages/BlogPostTemplate";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 200);
  }, []);

  if (isLoading) {
    return <div></div>;
  }

  return (
    <Router>
      <div>
        <NavigationBar />
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route
            exact
            path="/services/managed-it-support"
            element={<ManagedITSupportPage />}
          />
          <Route
            exact
            path="/services/consulting-solutions"
            element={<ConsultingSolutionsPage />}
          />
          <Route exact path="/about-rps/" element={<AboutUsPage />} />
          <Route exact path="/resources/" element={<ResourcesPage />} />
          <Route path="/post/:postId" element={<BlogPostTemplate />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
