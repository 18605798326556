import React from "react";
import ServicePage from "../components/ServicePage";

function ConsultingSolutionsPage() {
  const title = "Consulting Solutions";

  const content = {
    title: "Maximize Your Potential with Our Consulting Services",
    paragraphs: [
      "Effective IT infrastructure is essential for the success of any business. At RightClick Professional Services, we provide expert IT consulting services to help businesses optimize their IT strategy and operations.",
      "Our team of experienced IT consultants offers a range of services, including IT assessments, system design and implementation, project management, and vendor management. We work closely with our clients to understand their unique business needs and create customized solutions to achieve their goals.",
      "As part of our IT consulting services, we perform a comprehensive IT assessment to identify areas of improvement and provide recommendations for optimizing system performance, security, and reliability.",
      "Choose RightClick Professional Services as your trusted IT consulting partner and unlock the full potential of your technology infrastructure. Contact us today to schedule a free consultation and learn more about our customized IT solutions.",
    ],
    mainOffer: {
      title: "Consulting Solution",
      description:
        "Looking to start or complete your Cloud Journey? Finding exceptional consultants can be a timely and difficult task—a byproduct of industry standards over the past 50 years. Historically, consulting companies have staffed large and expensive teams of people to place on projects as work is won. The result is overbooked, expensive, and non-specialized talent to solve your problems.",
      bulletPoints: [
        "RPS seamlessly tailors its services to align with the unique wants and needs of each client, ensuring a personalized experience",
        "We prioritize the retention of subject matter experts within its service pillars, preserving institutional knowledge and enhancing expertise",
        "By delivering a truly bespoke solution, RightClick empowers clients with agility, transparency, and exceptional value",
      ],
      illustration: "/images/illustrations/consulting-illustration.png",
    },
  };

  const services = [
    {
      title: "Architecture",
      description:
        "Let our experts look under the hood of your existing technology solution infrastructure or your next project. Our experts will advise your team with design recommendations that align with your business strategy, existing technology solutions, and operational objectives. We will consider data from a variety of sources to generate a detailed execution plan.",
    },
    {
      title: "Development",
      description:
        "Our development team specializes in building customizable applications to ensure proper integration with your environment. Our projects range from net new development to application modernization to software integration projects. Our team implements Agile Methodology to make the development process as transparent as possible.",
    },
    {
      title: "DevOps",
      description:
        "DevOps is the combination of cultural philosophies, practices, and tools that increases an organization’s ability to deliver applications at high velocity. Improving applications at a faster pace than using traditional software development and infrastructure management processes. Where are you on your Devops journey? Let RPS help!",
    },
  ];

  return <ServicePage title={title} content={content} services={services} />;
}

export default ConsultingSolutionsPage;
