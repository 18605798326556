import React from "react";
import ServicePage from "../components/ServicePage";
function ManagedITSupportPage() {
  const title = "Managed IT Solutions";

  const content = {
    title: "Elevate Your Business with Customized Managed IT Services",
    paragraphs: [
      "Managed IT services are a critical component for the success of any business. They provide expert network and system management, allowing businesses to focus on their core operations while ensuring a stable technology infrastructure.",
      "At RightClick Professional Services, we offer tailored managed IT services to businesses in Fairfield County CT and beyond. Our proactive monitoring and maintenance of systems minimize downtime, ensure data security, and optimize network performance. We have a team of professionals who can offer assets like a Fractional CTO (Chief Technology Officer) to help businesses develop an effective IT strategy.",
      "As part of our managed IT services, we perform an initial network assessment to identify any deficiencies in your current infrastructure. This allows us to create a customized plan to upgrade your systems to a baseline level of performance.",
      "Choose RightClick Professional Services as your managed IT services provider and experience peace of mind, knowing that your technology infrastructure is in the hands of experts. Contact us today to schedule a free consultation and learn more about our customized IT solutions.",
    ],
    mainOffer: {
      title: "Fractional CTO",
      description:
        "At RPS, we understand that technology is constantly evolving, and it can be challenging to keep up with the latest advancements. That's why we're excited to offer our clients a complimentary Fractional CTO service as part of all our flexible packages. Your Fractional CTO will work closely with your team to ensure that your company's technology is aligned with your business goals and objectives.",
      bulletPoints: [
        "Conducting quarterly technology audits to assess your current landscape and create a strategy that will help you stay ahead of your competition",
        "Staying on the lookout for new technologies that could benefit your business",
        "Work with your team to develop their knowledge and comfort around your technology landscape",
      ],
      illustration: "/images/illustrations/itservices-illustration.png",
    },
  };

  const services = [
    {
      title: "Health",
      description:
        "We understand that technology is the backbone of your business, and that's why we provide 24/7 monitoring to ensure that your systems are always up and running. Our team of experts uses cutting-edge tools and techniques to keep an eye on your network, servers, and applications, so we can quickly identify and resolve any issues that arise.",
    },
    {
      title: "IT Security",
      description:
        "Cybersecurity is a top priority for any business, and we take it seriously. We provide a comprehensive suite of security solutions that protect your data and systems from threats like viruses, malware, and hackers. Our team of experts will work with you to implement the right security measures for your business, including firewalls, intrusion detection systems, and encryption.",
    },
    {
      title: "Support",
      description:
        "We know that technology can be complex, and that's why we provide expert support whenever you need it. Our team of technicians is available 24/7 to help you with any issues that may arise. We also offer remote support so we can quickly troubleshoot and fix any problem you may have. Whether you need help with a software installation or have a question about a specific service, we're here to help.",
    },
  ];

  return <ServicePage title={title} content={content} services={services} />;
}

export default ManagedITSupportPage;
