import { createClient } from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const sanityConfig = createClient({
  projectId: "s6upqocu",
  dataset: "production",
  useCdn: true,
});

// Create an image URL builder
export const imageBuilder = imageUrlBuilder(sanityConfig);

// Helper function to generate optimized image URLs
export function imageUrl(source) {
  return imageBuilder.image(source);
}
