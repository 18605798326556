import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

function ReviewsCarousel() {
  const handleDragStart = (e) => e.preventDefault();

  const items = [
    <div className="slider-item">
      <div
        className="alice-carousel__stage-item | flow"
        style={{ "--flow-spacer": "1rem" }}
      >
        <div className="slider-content">
          <h3 className="fw-bold">CEO at Staffing & Recruiting Firm</h3>
          <p data-width="wide">
            "We needed an MSP that could help us keep all our remote employees
            connected. This company has exceeded our expectations by providing
            us with a reliable remote access solution that has kept our business
            running smoothly."
          </p>
        </div>
      </div>
    </div>,
    <div className="slider-item">
      <div
        className="alice-carousel__stage-item | flow"
        style={{ "--flow-spacer": "1rem" }}
      >
        <div className="slider-content">
          <h3 className="fw-bold">General Manager at Country Club</h3>
          <p data-width="wide">
            "Our old technology was archaeic and RightClick Professional
            Services helped us modernize everything. I feel 10x more productive
            throughout the day since optimizing our technology!"
          </p>
        </div>
      </div>
    </div>,
    <div className="slider-item">
      <div
        className="alice-carousel__stage-item | flow"
        style={{ "--flow-spacer": "1rem" }}
      >
        <div className="slider-content">
          <h3 className="fw-bold">CEO at Real Estate Investment Firm</h3>
          <p data-width="wide">
            "RPS helps us manage our vast amounts of data while ensuring its
            security. Because of our new security features, we have received
            more funding which has allowed us to grow our business."
          </p>
        </div>
      </div>
    </div>,
    <div className="slider-item">
      <div
        className="alice-carousel__stage-item | flow"
        style={{ "--flow-spacer": "1rem" }}
      >
        <div className="slider-content">
          <h3 className="fw-bold">Owner at Restaurant</h3>
          <p data-width="wide">
            "As the owner of a restaurant group, it is extremely important that
            all of our locations operate together without downtime. RightClick
            Professional Services has alleviated any issues that might come from
            unexpected downtime."
          </p>
        </div>
      </div>
    </div>,
  ];

  return (
    <AliceCarousel
      mouseTracking
      items={items}
      responsive={{
        768: { items: 2 },
        1024: { items: 3 },
      }}
      dotsDisabled={false} // enable dots
      buttonsDisabled={false} // enable arrows
      infinite={true}
      autoPlay={true}
      autoPlayInterval={5000}
      disableSlideInfo
      touchTracking
      slideSpacing={16} // set spacing in between items
      autoHeight={false} // enable auto height
    />
  );
}

export default ReviewsCarousel;
