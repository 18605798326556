import React from "react";

import "../scripts/main";
import "../styles/main.css";

const ServiceContent = (props) => {
  const content = props.content;
  return (
    <div>
      <section className="padding-block-700">
        <div className="container">
          <hr className="divider" />
          <h2
            className="text-center large-subheader"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            {content.title}
          </h2>
          <hr className="divider" />
          {content.paragraphs.map((paragraph, index) => (
            <p
              className="wide"
              key={index}
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {paragraph}
            </p>
          ))}
        </div>
      </section>

      <section className="padding-block-700">
        <div className="container">
          <div className="even-columns">
            <div
              className="flow text-center-sm-only margin-bottom-700"
              style={{ "--flow-spacer": "1.5rem" }}
            >
              <h2
                className="fs-secondary-heading fw-bold"
                data-aos="fade-up"
                data-aos-duration="800"
              >
                {content.mainOffer.title}
              </h2>
              <div data-aos="fade-up" data-aos-duration="1000">
                {content.mainOffer.description}
              </div>
              <ul>
                {content.mainOffer.bulletPoints.map((bulletPoint, index) => (
                  <li
                    className="wide"
                    key={index}
                    data-aos="fade-up"
                    data-aos-duration="800"
                  >
                    {bulletPoint}
                  </li>
                ))}
              </ul>
            </div>
            <div data-aos="zoom-in" data-aos-duration="1000">
              <img src={content.mainOffer.illustration} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceContent;
