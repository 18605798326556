import React, { useEffect } from "react";

// import "../scripts/main";
import "../styles/main.css";
import { handleDOMContentLoaded } from "../scripts/main";

function NavigationBar() {
  useEffect(() => {
    handleDOMContentLoaded();
  }, []);
  return (
    <header className="primary-header">
      <div className="container">
        <div className="nav-wrapper">
          <a href="/">
            <img
              className="nav-logo"
              src="/images/logos/logo-color.png"
              alt="logo"
            />
          </a>
          <button
            className="mobile-nav-toggle"
            aria-controls="primary-navigation"
            aria-expanded="false"
          >
            <span className="visually-hidden">Menu</span>
          </button>
          <nav className="primary-navigation" id="primary-navigation">
            <ul role="list" aria-label="Primary" className="nav-list">
              <li>
                <a href="/services/managed-it-support/">Managed IT Solutions</a>
              </li>
              <li>
                <a href="/services/consulting-solutions/">
                  Consulting Solutions
                </a>
              </li>
              <li>
                <a href="/about-rps/">About Us</a>
              </li>
              <li>
                <a href="/resources/">Resources</a>
              </li>
            </ul>
          </nav>
          <a
            href="https://calendly.com/dgoldshore-rps/rps-introduction?month=2023-04"
            target="_blank"
            rel="noreferrer"
            className="button | display-sm-none display-md-inline-flex"
          >
            Contact Us
          </a>
        </div>
      </div>
    </header>
  );
}

export default NavigationBar;
