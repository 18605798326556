import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import Header from "../components/Header";
import ServiceContent from "../components/ServiceContent";
import ServiceBoxes from "../components/ServiceBoxes";

function ServicePage({ title, content, services }) {
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <>
      <Header title={title} />
      <ServiceContent content={content} />
      <ServiceBoxes services={services} />
    </>
  );
}

export default ServicePage;
